<template>
  <div class="row">
    <div class="col-lg-12 pb-5" v-if="status_pengerjaan == 'Selesai'">
      <a href="#" @click="$router.go(-1)"
        ><i class="fa fa-chevron-left" style="color: #0073e9"></i> Kembali</a
      >
    </div>
    <div class="col-lg-12 pb-5" v-else>
      <div class="row">
        <div class="col-lg-9 col-12">
          <b-card
            bg-variant="success"
            text-variant="white"
            title="Petunjuk Post Test"
            class="h-100"
          >
            <b-card-text>
              <div class="instructions pl-5">
                <ol>
                  <li>Bacalah setiap pertanyaan dengan seksama.</li>
                  <li>
                    Pilih jawaban yang menurut Anda paling benar di antara
                    pilihan yang tersedia.
                  </li>
                  <li>
                    Tandai jawaban Anda pada lembar jawaban yang disediakan
                    dengan memilih pilihan yang sesuai dengan pilihan Anda.
                  </li>
                  <li>
                    Hanya satu jawaban yang benar untuk setiap pertanyaan.
                  </li>
                </ol>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-lg-3 col-12">
          <b-card class="h-100">
            <b-card-text>
              <div>
                <strong>Waktu Mulai</strong>
              </div>
              <div>
                {{ dataKegiatan[0].waktu_awal_posttest }}
              </div>
              <div>
                <strong>Waktu Selesai</strong>
              </div>
              <div>
                {{ dataKegiatan[0].waktu_akhir_posttest }}
              </div>
              <div>
                <strong>Sisa Waktu</strong>
              </div>
              <div>
                <strong class="text-primary">{{
                  finished ? "Selesai" : display
                }}</strong>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-12 col-sm-12 h-100">
      <div class="card card-custom gutter-b p-5 h-100">
        <div v-for="(data, i) in questions" :key="i">
          <div v-if="i == currentQuestionIndex">
            <b-form-group
              v-if="data.tipe == 'Pilihan Ganda'"
              :disabled="status_pengerjaan == 'Selesai'"
              :label="`${i + 1}. ${data.soal}`"
            >
              <b-form-radio
                v-model="answer[currentQuestionIndex]"
                :name="'some-radios' + i"
                value="A"
              >
                A. {{ data.opsi_a }}
              </b-form-radio>

              <b-form-radio
                v-model="answer[currentQuestionIndex]"
                :name="'some-radios' + i"
                value="B"
              >
                B. {{ data.opsi_b }}
              </b-form-radio>

              <b-form-radio
                v-model="answer[currentQuestionIndex]"
                :name="'some-radios' + i"
                value="C"
              >
                C. {{ data.opsi_c }}
              </b-form-radio>

              <b-form-radio
                v-model="answer[currentQuestionIndex]"
                :name="'some-radios' + i"
                value="D"
              >
                D. {{ data.opsi_d }}
              </b-form-radio>

              <b-form-radio
                v-if="data.opsi_e != null"
                v-model="answer[currentQuestionIndex]"
                :name="'some-radios' + i"
                value="E"
              >
                E. {{ data.opsi_e }}
              </b-form-radio>
            </b-form-group>

            <!-- True False -->
            <b-form-group
              v-else
              :label="`${i + 1}. ${data.soal}`"
              :disabled="status_pengerjaan == 'Selesai'"
            >
              <b-form-radio
                v-model="answer[currentQuestionIndex]"
                :name="'some-radiostrue' + i"
                value="A"
              >
                A. {{ data.opsi_a }}
              </b-form-radio>

              <b-form-radio
                v-model="answer[currentQuestionIndex]"
                :name="'some-radiostrue' + i"
                value="B"
              >
                B. {{ data.opsi_b }}
              </b-form-radio>
            </b-form-group>
            <span v-if="status_pengerjaan == 'Selesai'"
              >Jawaban :
              <strong style="color: black">{{
                data.jawaban_benar
              }}</strong></span
            >
          </div>
        </div>
      </div>

      <div v-if="status_pengerjaan != 'Selesai'">
        <b-button
          size="sm"
          @click="navigateToQuestion(currentQuestionIndex - 1)"
          v-if="currentQuestionIndex != 0"
          variant="warning"
        >
          Sebelumnya</b-button
        >
        &nbsp;
        <b-button
          class="float-right"
          variant="primary"
          size="sm"
          @click="navigateToQuestion(currentQuestionIndex + 1)"
          v-if="currentQuestionIndex != questions.length - 1"
        >
          Selanjutnya
        </b-button>

        <b-button
          class="float-right"
          variant="success"
          size="sm"
          v-else
          @click="selesai"
        >
          Selesai
        </b-button>
      </div>

      <div v-else>
        <b-button
          size="sm"
          @click="navigateToQuestionDone(currentQuestionIndex - 1)"
          v-if="currentQuestionIndex != 0"
          variant="warning"
        >
          Sebelumnya</b-button
        >
        &nbsp;
        <b-button
          class="float-right"
          variant="success"
          size="sm"
          @click="navigateToQuestionDone(currentQuestionIndex + 1)"
          v-if="currentQuestionIndex != questions.length - 1"
        >
          Selanjutnya
        </b-button>

        <b-button
          class="float-right"
          variant="success"
          size="sm"
          v-else
          @click="$router.go(-1)"
        >
          Selesai
        </b-button>
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-sm-12 h-100">
      <div class="card card-custom gutter-b px-4 py-3 h-100">
        <h3>SOAL</h3>
        <div class="container">
          <div class="row row-cols-5">
            <div
              class="d-flex flex-wrap"
              v-for="(question, index) in questions"
              :key="index"
              variant="success"
            >
              <b-button
                v-if="status_pengerjaan != 'Selesai'"
                :style="{
                  'background-color':
                    status_jawaban[index] == '-'
                      ? 'rgb(231, 230, 230)'
                      : '#494b74',
                  color: status_jawaban[index] == '-' ? 'black' : 'white',
                }"
                class="button flex-grow-1"
                :class="{ 'button-active': currentQuestionIndex === index }"
                @click="navigateToQuestion(index)"
                variant="success"
              >
                {{ index + 1 }}
              </b-button>
              <b-button
                v-else
                :style="{
                  'background-color':
                    status_jawaban[index] == question.jawaban_benar
                      ? '#1BC5BD'
                      : '#F64E60',
                  color: 'white',
                }"
                class="button flex-grow-1"
                :class="{ 'button-active': currentQuestionIndex === index }"
                @click="navigateToQuestionDone(index)"
                variant="success"
              >
                {{ index + 1 }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Post-Test",
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      questions: [],
      answer: [],
      status_pengerjaan: null,
      currentQuestionIndex: 0, // Index of the current question
      dataKegiatan: [],
      now: null,
      end: null,
      tick: null,
    };
  },
  mounted() {
    this.loadAnswer();
    this.loadQuestion();
    this.loadKegiatan();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Post Test" }]);

    // Add this line to show the modal when the component is mounted
    this.$bvModal.show("modal-1");
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
        this.axios
          .put(
            this.url + "/jawaban_peserta/" + this.$route.query.answerID,
            {
              id_peserta: this.$route.query.scheduleID,
              jenis_ujian: "Post Test",
              jawaban: this.answer,
              status_pengerjaan: "Selesai",
            },
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.$router.go(-1);
          });
      }
    },
  },
  computed: {
    remaining() {
      var entek = moment(this.dataKegiatan[0].waktu_akhir_posttest);
      this.end = entek;
      return moment.duration(entek.diff(this.now));
      // return this.end.diff(this.now).toObject()
    },
    display() {
      return moment.utc(this.remaining.asMilliseconds()).format("HH:mm:ss");
    },
    finished() {
      return (
        this.now >=
        moment(this.dataKegiatan[0].waktu_akhir_posttest).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      );
    },
  },
  methods: {
    navigateToQuestion(index) {
      this.simpanJawaban();
      this.currentQuestionIndex = index;
      // Add your navigation logic here
    },
    navigateToQuestionDone(index) {
      this.currentQuestionIndex = index;
    },
    loadQuestion() {
      this.axios
        .get(
          this.url +
            "/soal_ujian?filter=id_kegiatan,=," +
            this.$route.params.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.questions = response.data.data;
        });
    },
    loadAnswer() {
      this.axios
        .get(this.url + "/jawaban_peserta?id=" + this.$route.query.answerID, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.answer = response.data.data.jawaban;
          // for (var i = 0; i < this.answer; i++) {
          //   this.questions[i].selected = this.answer[i];
          // }

          this.status_pengerjaan = response.data.data.status_pengerjaan;
          if (this.status_pengerjaan != "Selesai") {
            this.tick = setInterval(() => {
              this.now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            }, 100);
          }
          this.status_jawaban = response.data.data.jawaban;
        });
    },
    loadKegiatan() {
      this.axios
        .get(
          this.url + "/jadwal_kegiatan?filter=id,=," + this.$route.params.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataKegiatan = response.data.data;
        });
    },
    simpanJawaban() {
      this.axios
        .put(
          this.url + "/jawaban_peserta/" + this.$route.query.answerID,
          {
            id_peserta: this.$route.query.scheduleID,
            jenis_ujian: "Post Test",
            jawaban: this.answer,
          },
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {});
    },

    selesai() {
      Swal.fire({
        title: "Selesai",
        text: "Apakah Anda yakin ingin submit jawaban dan keluar dari Post Test?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .put(
              this.url + "/jawaban_peserta/" + this.$route.query.answerID,
              {
                id_peserta: this.$route.query.scheduleID,
                jenis_ujian: "Post Test",
                jawaban: this.answer,
                status_pengerjaan: "Selesai",
              },
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((response) => {
              this.$router.go(-1);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  width: 20px;
  height: 40px;
  margin: 5px;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: black;
  background-color: rgb(231, 230, 230);
  border-radius: 10px;
  border: #0056b3;
  transition: all 0.3s ease 0s;
}

.button-active {
  border-style: solid;
  border-color: #494b74 !important;
  border-width: 1px;
}
</style>
